import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { apiBase } from "apiConfig"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const ProjectsCreateAward = () => {
  const fd = useRef()
  //meta title
  document.title = "Create Our Project "

  const [projectState, setProjectState] = useState("")
  const [newDetails, setNewDetails] = useState("")
  const [projectDetails, setProjectDetails] = useState([])
  const [nearThings, setNearThings] = useState([])

  const [selectedFiles, setselectedFiles] = useState([])
  const [name, setName] = useState("")
  const [details, setDetails] = useState("")
  const [price, setPrice] = useState("")

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create Award project" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create Award Project</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Project Name
                      </Label>
                      <Col lg="10">
                        <Input
                          value={name}
                          onChange={e => setName(e.target.value)}
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          placeholder="Enter Project Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Project Description
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          rows="3"
                          name="description"
                          placeholder="Enter Project Description..."
                          value={details}
                          onChange={e => setDetails(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                  <Row className="mb-4">
                    <Label className="col-form-label col-lg-2">Images</Label>
                    <Col lg="10">
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input ref={fd} {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button
                        type="submit"
                        color="primary"
                        onClick={e => {
                          if (projectState === "") {
                            alert("Please select state")
                            return
                          }
                          if (name === "") {
                            alert("Please select state")
                            return
                          }
                          if (price === "") {
                            alert("Please select state")
                            return
                          }
                          createProject(
                            {
                              title: name,
                              details,
                              price,
                              selectedFiles,
                              projectState,
                              projectDetails,
                            },
                            () => {
                              alert("project Created")
                            }
                          )
                        }}
                      >
                        Create Project
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreateAward

function createProject(project, cb) {
  var myHeaders = new Headers()
  // myHeaders.append("Content-Type", "application/json")
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AqyOKlrycbTf0JvQohWN4yOCziPFDrvjp.AjbVO3tvRct0AcrtfDI85SCf%2FEREevGmU9y6ifeiDUs"
  )

  var formdata = new FormData()
  formdata.append("title", project.title)
  formdata.append("location", project.details)
  formdata.append("price", project.price)
  formdata.append("status", project.projectState)
  formdata.append("about", JSON.stringify(project.projectDetails))
  project.selectedFiles.map(file => {
    formdata.append("file", file, file.name)
  })
  console.log(formdata)
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  }

  fetch(apiBase + "/Properties", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log("error", error))
}
