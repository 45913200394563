import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardHeader,
} from "reactstrap"
import { Link } from "react-router-dom"
import "./dashboard.scss"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { apiBase } from "apiConfig"
import AwardsReceive from "./Award"
import ContactInfo from "./ContactInfo"

const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  // const reports = [
  //   { title: "Projects", iconClass: "bx-copy-alt", description: "15" },
  //   {
  //     title: "Completed Projects",
  //     iconClass: "bx-archive-in",
  //     description: "10",
  //   },
  //   {
  //     title: "Ongoing Projects",
  //     iconClass: "bx-purchase-tag-alt",
  //     description: "5",
  //   },
  // ]
  const [projectKpi, setProjectKpi] = useState({})
  const [residentialtKpi, setRESProjectKpi] = useState({})
  useEffect(() => {
    LoadKIP(res => {
      try {
        setProjectKpi(JSON.parse(res))
      } catch (e) {
        console.log(e)
      }
    })
    RESIDENTIAL(res => {
      try {
        setRESProjectKpi(JSON.parse(res))
      } catch (e) {
        console.log(e)
      }
    })
  }, [])

  const [periodData, setPeriodData] = useState([])

  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Baladi"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col md="7">
              <Card>
                <CardBody>
                  <Row className="border-bottom">
                    <Col md="2">
                      <div className=" avatar-md rounded-circle">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={"bx " + "bx-home" + " font-size-28"}
                          ></i>
                        </span>
                      </div>
                    </Col>
                    <Col className=" custombox-101-22">
                      <div className="card-number-101-2">
                        {residentialtKpi?.length + projectKpi?.length}
                      </div>
                      <div className="card-header-101-1">Total Projects</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="custombox-101-23">
                      <Row>
                        <Col md="2">
                          <div className="text-style-101-3 ">
                            <img
                              className="padding-left-10"
                              src={
                                "https://goldenproperty.v2.rayabharitechnologies.com/static/media/goldenPropertyLogo.9ba82fce95f3a7f34347bdf8945cb80d.svg"
                              }
                            />{" "}
                            <img
                              src={
                                "https://goldenproperty.v2.rayabharitechnologies.com/static/media/goldenPropertyLogoText.66b737aca49fa3a5456eb84200f3c5bd.svg"
                              }
                            />
                          </div>
                        </Col>
                        <Col className=" custombox-101-22-1">
                          <div className="card-number-101-2-1">
                            {residentialtKpi?.length}
                          </div>
                          <div className="card-header-101-1-1">
                            Residential Plots
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="custombox-101-23-1">
                      <Row>
                        <Col md="2">
                          <div className="text-style-101-3 ">
                            <img
                              className="padding-left-10"
                              src={
                                "https://goldenproperty.v2.rayabharitechnologies.com/static/media/dakshaPropertyLogo.2778b2f045d4549ac5e9850801e32eb5.svg"
                              }
                            />{" "}
                            <img
                              src={
                                "https://goldenproperty.v2.rayabharitechnologies.com/static/media/dakshaPropertyLogoText.b94ac2259e8adf2c6795f8d1d64c1a3c.svg"
                              }
                            />
                          </div>
                        </Col>
                        <Col className=" custombox-101-22-1">
                          <div className="card-number-101-2-1">
                            {projectKpi?.length}
                          </div>
                          <div className="card-header-101-1-1">Farm lands</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="5">
              <Card>
                <div className="card-header-101-1 space-2232">
                  Project Overview
                </div>
                <ReactApexChart
                  className="chart-22334"
                  height="240"
                  options={{
                    chart: {
                      type: "donut",
                    },
                    stroke: {
                      width: 4,
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "25%",
                        },
                      },
                    },
                    labels: ["Residential plots", "Farm lands"],
                    height: 80,
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200,
                          },
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                  }}
                  series={[residentialtKpi?.length, projectKpi?.length]}
                  type="donut"
                />
              </Card>
            </Col>
          </Row>
          <Col className="" md="4">
            <Card style={{ padding: 30 }}>
              <div className="card-number-101-2-1">{projectKpi?.length}</div>
              <div className="card-header-101-1-1">Award Count</div>
            </Card>
          </Col>
          {/* <Row>
            <Col xl="12">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"RESIDENTIAL PLOTS"}
                          </p>
                          <h4 className="mb-0">{residentialtKpi.length}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-archive-in" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{"FARM LAND"}</p>
                          <h4 className="mb-0">{projectKpi.length}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-archive-in" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                
              </Row>
            </Col>
          </Row> */}

          <Row>
            <Col xl="4">{/* <SocialSource /> */}</Col>
            <Col xl="4">{/* <ActivityComp /> */}</Col>

            <Col xl="4">{/* <TopCities /> */}</Col>
          </Row>

          <Row>
            <Col lg="12">{/* <LatestTranaction /> */}</Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)

function LoadKIP(cb) {
  var myHeaders = new Headers()
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AYh-OY6KHyyLgOtZ2_OEZq1pyH35-aqLF.9CJpSUnzK0P3e6pADOUK18rvsRQNEyAA2URBpyXUBvg"
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  }

  fetch(apiBase + "/properties?type=FARMLAND", requestOptions)
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}

function RESIDENTIAL(cb) {
  var myHeaders = new Headers()
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AYh-OY6KHyyLgOtZ2_OEZq1pyH35-aqLF.9CJpSUnzK0P3e6pADOUK18rvsRQNEyAA2URBpyXUBvg"
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  }

  fetch(apiBase + "/properties?type=RESIDENTIAL-PLOTS", requestOptions)
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}
