import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { apiBase } from "apiConfig"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ProjectsCreate = () => {
  const fd = useRef()
  //meta title
  document.title = "Create New Project "

  const [projectState, setProjectState] = useState("")
  const [newDetails, setNewDetails] = useState("")
  const [projectDetails, setProjectDetails] = useState([])
  const [nearThings, setNearThings] = useState([])
  
  const [selectedFiles, setselectedFiles] = useState([])
  const [name, setName] = useState("")
  const [details, setDetails] = useState("")
  const [price, setPrice] = useState("")

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Project</CardTitle>
                  <Form>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Project Name
                      </Label>
                      <Col lg="10">
                        <Input
                          value={name}
                          onChange={e => setName(e.target.value)}
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          placeholder="Enter Project Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        Project Description
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          rows="3"
                          name="description"
                          placeholder="Enter Project Description..."
                          value={details}
                          onChange={e => setDetails(e.target.value)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        Budget
                      </label>
                      <Col lg="10">
                        <Input
                          id="projectbudget"
                          name="projectbudget"
                          type="text"
                          placeholder="Enter Project Budget..."
                          className="form-control"
                          value={price}
                          onChange={e => setPrice(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        Project Details
                      </label>
                      <Col lg="10">
                        {projectDetails.map((d, i) => (
                          <div
                            key={"pd" + i}
                            style={{
                              border: "1px solid #ccc",
                              padding: 10,
                              margin: 5,
                              display: "flex",
                            }}
                          >
                            <div style={{ flex: 1 }}>{d}</div>
                            <div
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={e => {
                                let t = [...projectDetails]
                                t.splice(i, 1)
                                console.log(i, t)
                                setProjectDetails(t)
                              }}
                            >
                              DELETE
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            border: "1px solid #ccc",
                            padding: 10,
                            margin: 5,
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: 5, flex: 1 }}>
                            <Input
                              type="text"
                              className="form-control"
                              value={newDetails}
                              onChange={e => setNewDetails(e.target.value)}
                            />
                          </div>
                          <Button
                            color="primary"
                            size="small"
                            style={{}}
                            onClick={e => {
                              let t = [...projectDetails]
                              t.push(newDetails)
                              setProjectDetails(t)
                              setNewDetails("")
                            }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="projectbudget"
                        className="col-form-label col-lg-2"
                      >
                        State
                      </label>
                      <Col lg="10">
                        {/* <Input
                          id="projectbudget"
                          name="projectbudget"
                          type="text"
                          placeholder="Enter Project Budget..."
                          className="form-control"
                          value={price}
                          onChange={e => setPrice(e.target.value)}
                        /> */}
                        <UncontrolledDropdown group>
                          <div color="primary"></div>
                          <DropdownToggle color="primary">
                            {" "}
                            {projectState || "Select Project Type"}
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            <DropdownItem
                              onClick={e => {
                                setProjectState("ESIDENTIAL-PLOTS")
                              }}
                            >
                              Residential
                            </DropdownItem>
                            <DropdownItem
                              onClick={e => {
                                setProjectState("FARMLAND")
                              }}
                            >
                              Form Land
                            </DropdownItem>
                            {/* <DropdownItem
                              onClick={e => {
                                setProjectState("completed")
                              }}
                            >
                              Completed
                            </DropdownItem> */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </FormGroup>
                  </Form>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="projectname"
                      className="col-form-label col-lg-2"
                    >
                      Units
                    </Label>
                    <Col lg="2">
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        Size
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="size"
                        name="size"
                        type="text"
                        className="form-control"
                        placeholder="Enter Size..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-20"
                      >
                        Project Size
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="projectname"
                        name="projectsize"
                        type="text"
                        className="form-control"
                        placeholder="Enter  Project Size..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-20"
                      >
                        Approvals
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="approvals"
                        name="approvals"
                        type="text"
                        className="form-control"
                        placeholder="Enter Approvals..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-20"
                      >
                        Location
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="location"
                        name="location"
                        type="text"
                        className="form-control"
                        placeholder="Enter Location..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="amenities"
                        className="col-form-label col-lg-20"
                      >
                        Amenities
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="amenities"
                        name="amenities"
                        type="text"
                        className="form-control"
                        placeholder="Enter Amenities..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="reraId"
                        className="col-form-label col-lg-20"
                      >
                        ReRaId
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="reraId"
                        name="reraId"
                        type="text"
                        className="form-control"
                        placeholder="Enter ReRaId..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="progress"
                        className="col-form-label col-lg-20"
                      >
                        Progress
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="progress"
                        name="progress"
                        type="text"
                        className="form-control"
                        placeholder="Enter Progress..."
                      />
                    </Col>
                    <Col lg="2">
                      <Label
                        htmlFor="khata"
                        className="col-form-label col-lg-20"
                      >
                        Khata
                      </Label>
                      <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="khata"
                        name="khata"
                        type="text"
                        className="form-control"
                        placeholder="Panchayath Khata..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <label
                      htmlFor="nearthings"
                      className="col-form-label col-lg-2"
                    >
                      Near Things
                    </label>
                    <Col lg="10">
                      {nearThings.map((d, i) => (
                        <div
                          key={"pd" + i}
                          style={{
                            border: "1px solid #ccc",
                            padding: 10,
                            margin: 5,
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>{d}</div>
                          <div
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={e => {
                              let t = [...nearThings]
                              t.splice(i, 1)
                              console.log(i, t)
                              setNearThings(t)
                            }}
                          >
                            DELETE
                          </div>
                        </div>
                      ))}
                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: 10,
                          margin: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ padding: 5, flex: 1 }}>
                          <Input
                            type="text"
                            className="form-control"
                            value={newDetails}
                            placeholder="Enter format like Sri Rampura Ring Road circle:9 KM"
                            onChange={e => setNewDetails(e.target.value)}
                          />
                        </div>
                        <Button
                          color="primary"
                          size="small"
                          style={{}}
                          onClick={e => {
                            let t = [...nearThings]
                            t.push(newDetails)
                            setNearThings(t)
                            setNewDetails("")
                          }}
                        >
                          Add More
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                  <Row className="mb-4">
                    <Label className="col-form-label col-lg-2">Images</Label>
                    <Col lg="10">
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input ref={fd} {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button
                        type="submit"
                        color="primary"
                        onClick={e => {
                          if (projectState === "") {
                            alert("Please select state")
                            return
                          }
                          if (name === "") {
                            alert("Please select state")
                            return
                          }
                          if (price === "") {
                            alert("Please select state")
                            return
                          }
                          createProject(
                            {
                              title: name,
                              details,
                              price,
                              selectedFiles,
                              projectState,
                              projectDetails,
                            },
                            () => {
                              alert("project Created")
                            }
                          )
                        }}
                      >
                        Create Project
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate

function createProject(project, cb) {
  var myHeaders = new Headers()
  // myHeaders.append("Content-Type", "application/json")
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AqyOKlrycbTf0JvQohWN4yOCziPFDrvjp.AjbVO3tvRct0AcrtfDI85SCf%2FEREevGmU9y6ifeiDUs"
  )

  var formdata = new FormData()
  formdata.append("title", project.title)
  formdata.append("location", project.details)
  formdata.append("price", project.price)
  formdata.append("status", project.projectState)
  formdata.append("about", JSON.stringify(project.projectDetails))
  project.selectedFiles.map(file => {
    formdata.append("file", file, file.name)
  })
  console.log(formdata);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  }

  fetch(apiBase + "/Properties", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log("error", error))
}
